import React,{ useState} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddData2 = () => {
    const navigate = useNavigate()
    const [cname,setCname] = useState("")
    const [cage,setCage] = useState("")
    const [cparty,setCparty] = useState("")
    const [cno,setCno] = useState("")
    const [totalvote,setTotalvote] = useState("")
    const [symbol,setSymbol] = useState("")
    const [imgurl,setImgurl] = useState("")
    

const handleSubmit = (e)=>{
    e.preventDefault()

    axios.post("http://localhost:3001/addCandidate2",{cname,cage,cparty,cno,totalvote,symbol,imgurl})
    .then(
     (response) => {
         //console.log(response.data)
       navigate('/Candidates')
     }
    )
    .catch(
     (err) => {
         console.log(err)
     }
    )
}

  return (
    <div>
    <form onSubmit={handleSubmit}>
    <input type='text' placeholder='enter cname' name='name' value={cname} onChange={(e)=>{setCname(e.target.value)}} required/>
    <input type='number' placeholder='enter cage' name='number' value={cage} onChange={(e)=>{setCage(e.target.value)}} required/>
    <input type='text' placeholder='enter cparty' name='text' value={cparty} onChange={(e)=>{setCparty(e.target.value)}} required/>
    <input type='number' placeholder='enter cno' name='number' value={cno} onChange={(e)=>{setCno(e.target.value)}} required/>
    <input type='number' placeholder='enter totalvote' name='number' value={totalvote} onChange={(e)=>{setTotalvote(e.target.value)}} required/>
    <input type='text' placeholder='enter symbol' name='image' value={symbol} onChange={(e)=>{setSymbol(e.target.value)}} required/>
    <input type='text' placeholder='enter imgurl' name='image' value={imgurl} onChange={(e)=>{setImgurl(e.target.value)}} required/>
    
    <input type='submit' value="submit" />
    </form>
    </div>
  )
}

export default AddData2;
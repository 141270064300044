import React from 'react'
import { useNavigate } from 'react-router-dom'

function Homepage() {
    const navigate = useNavigate()
  return (
    <>
    <center>
<h1>THINK BEFORE VOTING,ALL IS WELL</h1>
<h2>👉IT'S YOUR CHOICE👈</h2>

    <button className='btn1' onClick={() => navigate('/Voters')}>Voters</button>
    <button className='btn1' onClick={() => navigate('/Candidates')}>Candidates</button> 
    <button className='btn1' onClick={() => navigate('/Vote')}>Vote here</button>



    
</center>

<center>
<img src='https://img.freepik.com/premium-vector/crowd-indian-voters-showing-index-finger-after-voting-with-tricolor-brush-effect-election-day_1302-31473.jpg' width='950px'/> <br/>

<button className='btn1' onClick={() => navigate('/')}>←_←Go Back</button>
</center>
    </>
  )

}

export default Homepage
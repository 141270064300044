import React from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';

const List1 = (props) => {

  const navigate = useNavigate();

    console.log(props)
     const {id,uname,voteridno,gender,dob,fname,imgurl} = props
    
    
   

  
    
 

    //console.log(typeof(react))

    const handleDelete = () => {
      const id = props.id;
      try {
          axios.post("http://localhost:3001/delete1",{id})
          .than(response => {console.log(response)})
          .catch((error) => {console.log(error)});
          
      } catch (error) {
          console.log(error)
          
      }
  }

    
    

  return (
    <div className='DriverData'>
  
    <div>{uname}</div>
    <div>{voteridno}</div>
    <div>{gender}</div>
    <div>{dob}</div>
    <div>{fname}</div>
   <div> <img className='pic' src={props.imgurl}/> </div>
         
        <button onClick={handleDelete}>Delete</button>
                <button onClick={() => {navigate (`/Update1/${id}`)}}>Update</button>
    </div>
  )
}

export default List1;
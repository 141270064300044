import React,{useEffect,useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import List1 from './List1';

const Voters = () => {
    const navigate = useNavigate()
    const [voterData, setData] = useState([]);
      
    useEffect(() => {
      axios
      .get("http://localhost:3001/VoterData")
      .then(
        (response) => {
          setData(response.data);
       
        }
        )
      .catch(
        (error) => {
          console.log(error);
        }
        )
    }, [voterData]);
  return (
    <>
        <center><h1 className='txt'>voterlist</h1>
        <button onClick={()=>{navigate("/AddData1")}}> ADD DATA </button>

        </center>
        <div className='DriverList'>

        
        <div className='DriverData'>
        <div>uname</div>
        <div>voteridno</div>
        <div> gender</div>
        <div>dob</div>
        <div>fname</div>
        <div>imgurl</div>
       
      


    </div>

          {


            voterData.map(
              (voters) =>{
                              
                
                return(
                  <List1 
              key={voters.id}
              id={voters.id}
              uname ={voters.uname}
              voteridno ={voters.voteridno}
              gender ={voters.gender}
              dob ={voters.dob}
              fname ={voters.fname}
              imgurl ={voters.imgurl}
              
              />)}
            )
          }
            

        </div>

        
<center>
        <button className='btn1' onClick={() => navigate('/Homepage')}>←_←Go back</button>
</center>

    </>
  )
}

export default Voters;
import React,{useEffect,useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import List2 from './List2';

const Candidates = () => {
    const navigate = useNavigate()
    const [candidateData, setData] = useState([]);
    var winnervote = 0;
    var winnername="";
      
    useEffect(() => {
      axios
      .get("http://localhost:3001/CandidateData2")
      .then(
        (response) => {
          setData(response.data);
       
        }
        )
      .catch(
        (error) => {
          console.log(error);
        }
        )
    }, [candidateData]);
  return (
    <>
        <center><h1 className='txt'>candidatelist</h1>
        <button onClick={()=>{navigate("/AddData2")}}> ADD DATA </button>

        </center>
        <div className='DriverList'>

        <div className='DriverData'>
        <div>cname</div>
        <div>cage</div>
        <div> cparty</div>
        <div>cno</div>
        <div>totalvote</div>
        <div>symbol</div>
        <div>imgurl</div>
       
      


    </div>

          {


            candidateData.map(
              (candidates) =>{
                           if(candidates.totalvote > winnervote)   {winnervote = candidates.totalvote; winnername = candidates.cname} 
                
                return(
                  <List2 
              key={candidates.id}
              id={candidates.id}
              cname ={candidates.cname}
              cage ={candidates.cage}
              cparty ={candidates.cparty}
              cno ={candidates.cno}
               totalvote ={candidates.totalvote}
              symbol ={candidates.symbol}
              imgurl ={candidates.imgurl}
              
              
              />)}

              
            )
          }
          <div>
            <div className='win'>winnervote ={winnervote}</div>
            <div className='win'>winnername = {winnername}</div>
          </div>
            
          </div>


       

        
<center>
        <button className='btn1' onClick={() => navigate('/Homepage')}>←_←Go back</button>
</center>

    </>
  )
}

export default Candidates;
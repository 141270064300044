import React,{useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";

function Register() {
  const navigate = useNavigate('');

  const [usernameReg,setUsernameReg] = useState("");
  const [passwordReg,setPasswordReg] = useState("");

  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");

  const[loginStatus,setLoginStatus] = useState("");
  const[registerStatus,setRegisterStatus] = useState("");

  const register = () => {
    axios.post('http://localhost:3001/register',{
      username:usernameReg,
      password:passwordReg
    }).then((response) =>{
      // console.log(response);
    })
  }


  const login = () => {
    axios.post('http://localhost:3001/login',{
      username:username,
      password:password
    }).then((response) =>{
      if(response.data.message){
        setLoginStatus(response.data.message);
      } else{
        setLoginStatus('USERNAME:'+ response.data.username  +   ',PASSWORD:' + response.data.password);
        navigate('/Homepage');
      }
      // console.log(response);
    })
  }

  return(
    <div className='Register'>
       <div className='registration'>
       <h1>Register</h1>
      <label>Username</label>
      <input type='text' placeholder='enter username'
        onChange={(e)=> {setUsernameReg(e.target.value)}}
      />

      <label className='txt1'>password</label>
      <input type='text' placeholder='enter password'
        onChange={(e)=> {setPasswordReg(e.target.value)}}
      />
<button onClick={register} className='btn3'>Register</button>
<div>
      </div>




      <div className='login'></div>
      <h1>Login</h1>
      <input type='text' placeholder='Username'
        onChange={(e)=> {setUsername(e.target.value)}}
      />
      <input className='txt1' type='password' placeholder='password'
        onChange={(e)=> {setPassword(e.target.value)}}
      />
      <button onClick={login}  className='btn3'>Login</button>
    </div>
    <h1>{loginStatus}</h1>
    </div>
  )
}
export default Register;
import React,{useEffect,useState} from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';


const Vote = () => {
    const [CandidateData2, setCandidateData2] = useState([]);
    const [VoterData, setVoterData] = useState([]);  
    const [votedFor, setVotedFor] = useState("");

    useEffect(() => {
        axios
        .get("http://localhost:3001/CandidateData2")
        .then(
          (response) => {
            setCandidateData2(response.data);
         
          }
          )
        .catch(
          (error) => {
            console.log(error);
          }
          )
      }, [CandidateData2]);


      useEffect(() => {
        axios
        .get("http://localhost:3001/VoterData")
        .then(
          (response) => {
            setVoterData(response.data);
         
          }
          )
        .catch(
          (error) => {
            console.log(error);
          }
          )
      }, [VoterData]);



    const navigate = useNavigate();



        function handleChange(event){
            console.log(event.target.selectedIndex);
            console.log(CandidateData2[event.target.selectedIndex]);
            axios
        .post("http://localhost:3001/vote", CandidateData2[event.target.selectedIndex])
        .then(
          (response) => {
            console.log(response);
            alert(response.data);
         
          })
        .catch(
          (error) => {
            console.log(error);
          });
        
        }

        function handleChange1(event){
          console.log(event.target.selectedIndex);
          console.log(VoterData[event.target.selectedIndex]);
          axios
          .post("http://localhost:3001/vote1", VoterData[event.target.selectedIndex])
          .then((response) => {
            console.log(response);
            alert(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

  return (
    <>

<center>
<h1>Please vote here...</h1>
<div className='drop'> 
<h3>candidates </h3>
<Form.Select aria-label="Default select example" onChange={handleChange}> 

{CandidateData2.map((candidates) =>  {
  
    return(
        <option value ={candidates.cname}>{candidates.cname}</option>
    )
})}

</Form.Select>

</div> <br />


<div className='drop'>
<h3>voters </h3>
<Form.Select aria-label="Default select example" onChange={handleChange1}> 

{VoterData.map((voters) =>  {
  
    return(
        <option value ={voters.uname}>{voters.uname}</option>
    )
})}
</Form.Select>
</div>

    <button className='btn1' onClick={() => navigate('/Homepage')}>←_←Go back</button>
    <button className='btn1' onClick={handleChange}>press to vote</button>
</center>


</>
  )
}

export default Vote;
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";

const Update2 = () => {
const {id} = useParams();
const navigate = useNavigate();


const [cname,setCname] = useState("")
const [cage,setCage] = useState("")
const [cparty,setCparty] = useState("")
const [cno,setCno] = useState("")
const [totalvote,setTotalvote] = useState("")
const [symbol,setSymbol] = useState("")
 const [imgurl,setImgurl] = useState("")

useEffect(() => {
 axios.post("http://localhost:3001/getUpdateData2",{id})
 .then(res => {
    setCname(res.data.cname);
    setCage(res.data.cage);
    setCparty(res.data.cparty);
    setCno(res.data.cno);
    setTotalvote(res.data.totalvote);
    setSymbol(res.data.symbol);
    setImgurl(res.data.imgurl);
    
 })
 .catch(
  err => {console.log(err)},
 )
}, [id])


const handleSubmit = (e)=>{
  e.preventDefault();
  try{
    axios.post('http://localhost:3001/update2',{id,cname,cage,cparty,cno,totalvote,symbol,imgurl})
    .then((response) => {
      console.log(response);
      navigate('/candidates');
    })
    .catch((err) => {console.log(err)})
  } catch (error) {

  }
};

  return (
    <div>
       <form onSubmit={handleSubmit}>
       
       <input type='text' placeholder='enter cname' name='name' value={cname} onChange={(e)=>{setCname(e.target.value)}} required/>
    <input type='number' placeholder='enter cage' name='number' value={cage} onChange={(e)=>{setCage(e.target.value)}} required/>
    <input type='text' placeholder='enter cparty' name='text' value={cparty} onChange={(e)=>{setCparty(e.target.value)}} required/>
    <input type='number' placeholder='enter cno' name='number' value={cno} onChange={(e)=>{setCno(e.target.value)}} required/>
    <input type='number' placeholder='enter totalvote' name='number' value={totalvote} onChange={(e)=>{setTotalvote(e.target.value)}} required/>
    <input type='emoji' placeholder='enter symbol' name='image' value={symbol} onChange={(e)=>{setSymbol(e.target.value)}} required/>
    <input type='text' placeholder='enter imgurl' name='image' value={imgurl} onChange={(e)=>{setImgurl(e.target.value)}} required/>
    
    <input type='submit' value="submit" />
    </form>
    </div>
  )
}

export default Update2;
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";

const Update1 = () => {
const {id} = useParams();
const navigate = useNavigate();


const [uname,setUname] = useState("")
const [voteridno,setVoteridno] = useState("")
const [gender,setGender] = useState("")
const [dob,setDob] = useState("")
const [fname,setFname] = useState("")
const [imgurl,setImgurl] = useState("")

useEffect(() => {
 axios.post("http://localhost:3001/getUpdateData1",{id})
 .then(res => {
    setUname(res.data.uname);
    setVoteridno(res.data.voteridno);
    setGender(res.data.gender);
    setDob(res.data.dob);
    setFname(res.data.fname);
    setImgurl(res.data.imgurl);
    
 })
 .catch(
  err => {console.log(err)},
 )
}, [id])


const handleSubmit = (e)=>{
  e.preventDefault();
  try{
    axios.post('http://localhost:3001/update1',{id,uname,voteridno,gender,dob,fname,imgurl})
    .then((response) => {
      console.log(response);
      navigate('/Voters');
    })
    .catch((err) => {console.log(err)})
  } catch (error) {

  }
};

  return (
    <div>
       <form onSubmit={handleSubmit}>
       
       <input type='text' placeholder='enter username' name='number' value={uname} onChange={(e)=>{setUname(e.target.value)}} required/>
    <input type='number' placeholder='enter voteridno' name='number' value={voteridno} onChange={(e)=>{setVoteridno(e.target.value)}} required/>
    <input type='text' placeholder='enter gender' name='text' value={gender} onChange={(e)=>{setGender(e.target.value)}} required/>
    <input type='number' placeholder='enter dob' name='number' value={dob} onChange={(e)=>{setDob(e.target.value)}} required/>
    <input type='text' placeholder='enter fname' name='name' value={fname} onChange={(e)=>{setFname(e.target.value)}} required/>
    <input type='text' placeholder='enter imgurl' name='text' value={imgurl} onChange={(e)=>{setImgurl(e.target.value)}} required/>
    
    <input type='submit' value="submit" />
    </form>
    </div>
  )
}

export default Update1;
import React from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';

const List2 = (props) => {

  const navigate = useNavigate();

    console.log(props)
     const {id,cname,cage,cparty,cno,totalvote,symbol,imgurl} = props
  

    //console.log(typeof(react))

    const handleDelete = () => {
      const id = props.id;
      try {
          axios.post("http://localhost:3001/delete2",{id})
          .than(response => {console.log(response)})
          .catch((error) => {console.log(error)});
          
      } catch (error) {
          console.log(error)
          
      }
  }

    
    

  return (
    <div className='DriverData'>
  
    <div>{cname}</div>
    <div>{cage}</div>
    <div>{cparty}</div>
    <div>{cno}</div>
    <div>{totalvote}</div>
    <div>{symbol}</div>
   <div> <img className='pic' src={props.imgurl}/> </div>
        

       
        
    
        <button onClick={handleDelete}>Delete</button>
                <button onClick={() => {navigate (`/Update2/${id}`)}}>Update</button>
    </div>
  )
}

export default List2;
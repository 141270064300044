import React from 'react'
import {BrowserRouter,Routes,Route}from "react-router-dom";
import AddData1 from './components/AddData1'
import Homepage from './components/Homepage';
import Voters from './components/Voters';
import Update1 from './components/Update1';
import AddData2 from './components/AddData2';
import Candidates from './components/Candidates';
import Update2 from './components/Update2';
import Register from './components/Register';
import Vote from './components/Vote';




function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Register/>}/>
    <Route path='/Homepage' element={<Homepage/>}/>

        <Route path='/AddData1' element={<AddData1/>}/>
        <Route path='/Voters' element={<Voters/>}/>
        <Route path='/Update1/:id' element={<Update1/>}/>

        <Route path='/AddData2' element={<AddData2/>}/>
        <Route path='/Candidates' element={<Candidates/>}/>
        <Route path='/Update2/:id' element={<Update2/>}/>

        <Route path='/Vote' element={<Vote/>}/>



       

       
    </Routes>
    </BrowserRouter>
  )
}

export default App;
import React,{ useState} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const AddData1 = () => {
    const navigate = useNavigate()
    const [uname,setUname] = useState("")
    const [voteridno,setVoteridno] = useState("")
    const [gender,setGender] = useState("")
    const [dob,setDob] = useState("")
    const [fname,setFname] = useState("")
    const [imgurl,setImgurl] = useState("")
    

const handleSubmit = (e)=>{
    e.preventDefault()

    axios.post("http://localhost:3001/addVoter",{uname,voteridno,gender,dob,fname,imgurl})
    .then(
     (response) => {
         //console.log(response.data)
       navigate('/Voters')
     }
    )
    .catch(
     (err) => {
         console.log(err)
     }
    )
}

  return (
    <div>
    <form onSubmit={handleSubmit}>
    <input type='text' placeholder='enter username' name='number' value={uname} onChange={(e)=>{setUname(e.target.value)}} required/>
    <input type='number' placeholder='enter voteridno' name='number' value={voteridno} onChange={(e)=>{setVoteridno(e.target.value)}} required/>
    <input type='text' placeholder='enter gender' name='text' value={gender} onChange={(e)=>{setGender(e.target.value)}} required/>
    <input type='number' placeholder='enter dob' name='number' value={dob} onChange={(e)=>{setDob(e.target.value)}} required/>
    <input type='text' placeholder='enter fname' name='name' value={fname} onChange={(e)=>{setFname(e.target.value)}} required/>
    <input type='text' placeholder='enter imgurl' name='image' value={imgurl} onChange={(e)=>{setImgurl(e.target.value)}} required/>
    
    <input type='submit' value="submit" />
    </form>
    </div>
  )
}

export default AddData1;